var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex relative" },
    [
      _c("card", { staticClass: "mx-auto px-6  min-w-half" }, [
        _c(
          "div",
          {
            staticClass: "flex justify-end border-b border-gray-200 pb-2 mb-2",
          },
          [
            _c(
              "base-button",
              { on: { click: _vm.fetchData } },
              [_c("fv-icon", { attrs: { icon: "switch" } })],
              1
            ),
          ],
          1
        ),
        _vm.uiState === "loading"
          ? _c(
              "div",
              { staticClass: "mx-auto relative min-w-80" },
              [
                _c("spinner-overlay", {
                  attrs: { loading: true, color: "transparent", size: "xl" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-col space-y-3 items-baseline pb-8",
                  },
                  [
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-80",
                      staticStyle: { height: ".5rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-40",
                      staticStyle: { height: ".8rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-16",
                      staticStyle: { height: ".5rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-32",
                      staticStyle: { height: ".8rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-40",
                      staticStyle: { height: ".6rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-16",
                      staticStyle: { height: ".5rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-32",
                      staticStyle: { height: ".5rem" },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col space-y-3 items-baseline pb-8 mt-4 border-t border-gray-200",
                  },
                  [
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-80",
                      staticStyle: { height: ".5rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-40",
                      staticStyle: { height: ".8rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-16",
                      staticStyle: { height: ".5rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-32",
                      staticStyle: { height: ".8rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-40",
                      staticStyle: { height: ".6rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-16",
                      staticStyle: { height: ".5rem" },
                    }),
                    _c("div", {
                      staticClass: "bg-gray-400 rounded shadow w-32",
                      staticStyle: { height: ".5rem" },
                    }),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.uiState === "success"
          ? _c("div", { staticClass: "flex flex-col space-y-16" }, [
              _c(
                "div",
                {},
                [
                  _c("p", { staticClass: "text-2xl font-black mb-6 " }, [
                    _vm._v(" General "),
                  ]),
                  _c(
                    "desc-list",
                    [
                      _c("desc-item", [
                        _c("p", { staticClass: "text-gray-700" }, [
                          _vm._v(" Workday "),
                        ]),
                        _c("p", { staticClass: "font-semibold" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.countrySettings.workDay.start) +
                              " - " +
                              _vm._s(_vm.countrySettings.workDay.end) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("desc-item", [
                        _c("p", { staticClass: "text-gray-700" }, [
                          _vm._v(" Default timezone "),
                        ]),
                        _c("p", { staticClass: "font-semibold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.countrySettings.timezone) + " "
                          ),
                        ]),
                      ]),
                      _c("desc-item", [
                        _c("p", { staticClass: "text-gray-700" }, [
                          _vm._v(" Localization "),
                        ]),
                        _c("p", { staticClass: "font-semibold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.countrySettings.localization) + " "
                          ),
                        ]),
                      ]),
                      _c("desc-item", [
                        _c("p", { staticClass: "text-gray-700" }, [
                          _vm._v(" Booking margin "),
                        ]),
                        _c("p", { staticClass: "font-semibold" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.countrySettings.booking_margin) +
                              " minutes "
                          ),
                        ]),
                      ]),
                      _c("desc-item", [
                        _c("p", { staticClass: "text-gray-700" }, [
                          _vm._v(" Random ordering "),
                        ]),
                        _c("p", { staticClass: "font-semibold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.countrySettings.random_order ? "On" : "Off"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              !!Object.keys(_vm.countryPricing).length
                ? _c(
                    "div",
                    { staticClass: "flex flex-col" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between  mb-3",
                        },
                        [
                          _c("p", { staticClass: "text-2xl font-black" }, [
                            _vm._v(" Pricing "),
                          ]),
                          _c("p", { staticClass: "text-gray-700" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.countryPricing.currency.currency) +
                                ", " +
                                _vm._s(
                                  _vm.countryPricing.currency.currency_symbol
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "block mb-4" }, [
                        _c("p", {}, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.countryPricing.title) +
                              " - " +
                              _vm._s(_vm.countryPricing.description) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "desc-list",
                        [
                          _c(
                            "desc-item",
                            [
                              _c("p", { staticClass: "text-gray-700" }, [
                                _vm._v(" Normal "),
                              ]),
                              _c("desc-data", [
                                _c("span", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    _vm._s(_vm.countryPricing.price_normal) +
                                      " "
                                  ),
                                ]),
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.countryPricing.currency
                                          .currency_code
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "desc-item",
                            [
                              _c("p", { staticClass: "text-gray-700" }, [
                                _vm._v(" Inconvenient "),
                              ]),
                              _c("desc-data", [
                                _c("span", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.countryPricing.price_inconvenient
                                    ) + " "
                                  ),
                                ]),
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.countryPricing.currency
                                          .currency_code
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "desc-item",
                            [
                              _c("p", { staticClass: "text-gray-700" }, [
                                _vm._v(" Holidays "),
                              ]),
                              _c("desc-data", [
                                _c("span", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    _vm._s(_vm.countryPricing.price_holiday) +
                                      " "
                                  ),
                                ]),
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.countryPricing.currency
                                          .currency_code
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {},
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between  mb-3" },
                    [
                      _c("p", { staticClass: "text-2xl font-black" }, [
                        _vm._v(" Holidays "),
                      ]),
                      _c(
                        "p",
                        _vm._l(
                          _vm.countrySettings.holidaySettings.filters,
                          function (holidayFilter) {
                            return _c(
                              "span",
                              {
                                key: holidayFilter,
                                staticClass: "text-gray-800 mr-1 font-normal",
                              },
                              [_vm._v(" " + _vm._s(holidayFilter) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "block mb-6 " }, [
                    _vm._v(" " + _vm._s(_vm.holidayMap.length) + " in total "),
                  ]),
                  _vm._l(_vm.holidayMap, function (holiday) {
                    return _c(
                      "div",
                      {
                        key: holiday.date,
                        staticClass:
                          "flex items-center border border-gray-200 px-2 py-3 hover:bg-gray-100",
                      },
                      [
                        _c("span", [_vm._v(_vm._s(holiday.date))]),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(_vm._s(holiday.name) + " "),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }