<template>
  <div
    class="flex relative"
  >
    <card
      class="mx-auto px-6  min-w-half"
    >
      <div class="flex justify-end border-b border-gray-200 pb-2 mb-2">
        <base-button
          @click="fetchData"
        >
          <fv-icon icon="switch" />
        </base-button>
      </div>

      <div
        v-if="uiState === 'loading'"
        class="mx-auto relative min-w-80"
      >
        <spinner-overlay
          :loading="true"
          color="transparent"
          size="xl"
        />
        <div class="flex flex-col space-y-3 items-baseline pb-8">
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-80"
          />
          <div
            style="height: .8rem"
            class="bg-gray-400 rounded shadow w-40"
          />
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-16"
          />
          <div
            style="height: .8rem"
            class="bg-gray-400 rounded shadow w-32"
          />
          <div
            style="height: .6rem"
            class="bg-gray-400 rounded shadow w-40"
          />
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-16"
          />
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-32"
          />
        </div>
        <div class="flex flex-col space-y-3 items-baseline pb-8 mt-4 border-t border-gray-200">
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-80"
          />
          <div
            style="height: .8rem"
            class="bg-gray-400 rounded shadow w-40"
          />
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-16"
          />
          <div
            style="height: .8rem"
            class="bg-gray-400 rounded shadow w-32"
          />
          <div
            style="height: .6rem"
            class="bg-gray-400 rounded shadow w-40"
          />
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-16"
          />
          <div
            style="height: .5rem"
            class="bg-gray-400 rounded shadow w-32"
          />
        </div>
      </div>


      <div
        v-if="uiState === 'success'"
        class="flex flex-col space-y-16"
      >
        <div class="">
          <p class="text-2xl font-black mb-6 ">
            General
          </p>
          <desc-list>
            <desc-item>
              <p class="text-gray-700">
                Workday
              </p> 
              <p class="font-semibold">
                {{ countrySettings.workDay.start }} - {{ countrySettings.workDay.end }}
              </p>
            </desc-item>
            <desc-item>
              <p class="text-gray-700">
                Default timezone
              </p>
              <p class="font-semibold">
                {{ countrySettings.timezone }}
              </p>
            </desc-item>
            <desc-item>
              <p class="text-gray-700">
                Localization
              </p>
              <p class="font-semibold">
                {{ countrySettings.localization }}
              </p>
            </desc-item>
            <desc-item>
              <p class="text-gray-700">
                Booking margin
              </p>
              <p class="font-semibold">
                {{ countrySettings.booking_margin }} minutes
              </p>
            </desc-item>
            <desc-item>
              <p class="text-gray-700">
                Random ordering
              </p>
              <p class="font-semibold">
                {{ countrySettings.random_order ? 'On': 'Off' }}
              </p>
            </desc-item>
          </desc-list>
        </div>
  
        <div
          v-if="!!Object.keys(countryPricing).length"
          class="flex flex-col"
        >
          <div class="flex items-center justify-between  mb-3">
            <p class="text-2xl font-black">
              Pricing
            </p>
            <p class="text-gray-700">
              {{ countryPricing.currency.currency }}, {{ countryPricing.currency.currency_symbol }}
            </p>
          </div>
          <div class="block mb-4">
            <p class="">
              {{ countryPricing.title }} - {{ countryPricing.description }}
            </p>
          </div>
          <desc-list>
            <desc-item>
              <p class="text-gray-700">
                Normal
              </p>
              <desc-data>
                <span class="font-semibold">{{ countryPricing.price_normal }} </span>
                <span class="text-sm">
                  {{ countryPricing.currency.currency_code }}
                </span>
              </desc-data>
            </desc-item>
            <desc-item>
              <p class="text-gray-700">
                Inconvenient
              </p>
              <desc-data>
                <span class="font-semibold">{{ countryPricing.price_inconvenient }} </span>
                <span class="text-sm">
                  {{ countryPricing.currency.currency_code }}
                </span>
              </desc-data>
            </desc-item>
            <desc-item>
              <p class="text-gray-700">
                Holidays
              </p>
              <desc-data>
                <span class="font-semibold">{{ countryPricing.price_holiday }} </span>
                <span class="text-sm">
                  {{ countryPricing.currency.currency_code }}
                </span>
              </desc-data>
            </desc-item>
          </desc-list>
        </div>

        <div class="">
          <div class="flex items-center justify-between  mb-3">
            <p class="text-2xl font-black">
              Holidays
            </p>
            <p>
              <span
                v-for="holidayFilter in countrySettings.holidaySettings.filters"
                :key="holidayFilter"
                class="text-gray-800 mr-1 font-normal"
              >
                {{ holidayFilter }}
              </span>
            </p>
          </div>

          <div class="block mb-6 ">
            {{ holidayMap.length }} in total
          </div>
          <div
            v-for="holiday in holidayMap"
            :key="holiday.date"
            class="flex items-center border border-gray-200 px-2 py-3 hover:bg-gray-100"
          >
            <span>{{ holiday.date }}</span> <span class="ml-3">{{ holiday.name }} </span>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      uiState: 'idle',
    };
  },
  computed: {
    ...mapState('admin', [
      'countryId',
      'countrySettings',
      'countryHolidays',
      'countryPricing',
    ]),
    holidayMap() {
      return Object.keys(this.countryHolidays)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        })
        .map(date => {
          return { date, name: this.countryHolidays[date] };
        });
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    ...mapActions('admin', [
      'fetchCountrySettings',
      'fetchCountryHolidays',
      'fetchCountryPricing',
    ]),
    async fetchData() {
      this.uiState = 'loading';
      await Promise.all([
        this.fetchCountrySettings(),
        this.fetchCountryHolidays(),
        this.fetchCountryPricing(),
      ]);

      this.uiState = 'success';
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
